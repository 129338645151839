import React from 'react'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider
} from 'react-router-dom'
import FullScreenLoader from '@oi/react/components/full-screen-loader'

import App from './app'
import LoginRoute from './auth/login'

const appRoutes = createBrowserRouter(createRoutesFromElements(
  <Route>
    <Route
      element={<LoginRoute />}
      path={'/login'} />

    <Route
      element={<App />}
      id={'main'}
      path={'/'}>
      <Route
        lazy={() => import('./app/case')}
        path={'cases/:hash'} />

      <Route
        element={<Navigate to={'/login'} />}
        path={'/'} />
    </Route>

    <Route
      element={<Navigate to={'/login'} />}
      path={'*'} />
  </Route>
))

export default function Routes() {
  return (
    <RouterProvider
      fallbackElement={<FullScreenLoader withDelay />}
      router={appRoutes} />
  )
}
