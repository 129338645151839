import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import type {
  CaseTimelineStep,
  DebtorApiCreditCaseMessageCreateOneRequest,
  LoginWithPin
} from './open-api'

import { debtorapi } from './api.constants'
import { getCreditCaseQueryKey } from './api.credit-case'
import { unwrapResponse, useWrapMethod } from './api.internal'

export function useDebtorLoginMutation() {
  return useWrapMethod<
    LoginWithPin,
    string
  >((loginWithPin) => debtorapi.loginWithPin({ loginWithPin }))
}

export function useDebtorCreditCase() {
  const params = useParams<{ hash: string }>()

  return useQuery({
    queryKey: getCreditCaseQueryKey(params.hash as string),
    queryFn: () => unwrapResponse(debtorapi.creditCasesFindByHash({ hash: params.hash as string }))
  })
}

export function useDebtorCaseMessageMutation() {
  return useWrapMethod<
    DebtorApiCreditCaseMessageCreateOneRequest,
    CaseTimelineStep
  >(
    (params: DebtorApiCreditCaseMessageCreateOneRequest) => debtorapi.creditCaseMessageCreateOne(params)
  )
}
